/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as Const from '../../common/const';
import Page from '../../components/page';
import * as AMap from '../../common/amap';

import './index.less';

// import IMG_BG from '../../common/images/bg/bg1.png';
import IMG_TOP from './images/newspaper.png'
import IMG_LOGO from './images/logo.jpg'
import IMG_POI from './images/poi.png'

const areaList = [
	{ value: '青浦镇', label: '青浦镇', zipCode: '201700' },
	{ value: '青东农场', label: '青东农场', zipCode: '201701' },
	{ value: '徐泾', label: '徐泾', zipCode: '201702' },
	{ value: '赵巷', label: '赵巷', zipCode: '201703' },
	{ value: '凤溪', label: '凤溪', zipCode: '201705' },
	{ value: '重固', label: '重固', zipCode: '201706' },
	{ value: '香花', label: '香花', zipCode: '201707' },
	{ value: '华新', label: '华新', zipCode: '201708' },
	{ value: '白鹤', label: '白鹤', zipCode: '201709' },
	{ value: '赵屯', label: '赵屯', zipCode: '201711' },
	{ value: '大盈', label: '大盈', zipCode: '201712' },
	{ value: '朱家角', label: '朱家角', zipCode: '201713' },
	{ value: '沈巷', label: '沈巷', zipCode: '201714' },
	{ value: '练塘', label: '练塘', zipCode: '201715' },
	{ value: '小蒸', label: '小蒸', zipCode: '201716' },
	{ value: '蒸淀', label: '蒸淀', zipCode: '201717' },
	{ value: '金泽', label: '金泽', zipCode: '201718' },
	{ value: '商榻', label: '商榻', zipCode: '201719' },
	{ value: '西岑', label: '西岑', zipCode: '201721' },
	{ value: '莲盛', label: '莲盛', zipCode: '201722' },
];
const getAreaItem = value => {
	let target = null;
	for(let i = 0, len = areaList.length; i < len; i++) {
		if(areaList[i].value == value) {
			target = areaList[i];
		}
	}
	return target;
};

const PageModel = props => {
	const [subscribeList, setSubscribeList] = useState([1]);
	const [seleteItem, setSeleteItem] = useState(1);
	const [gender, setGender] = useState(1);
	const [userName, setUserName] = useState('');
	const [mobile, setMobile] = useState('');
	const [cityInfo, setCityInfo ] = useState({});
	const [address, setAddress ] = useState('');

	const history = useHistory();

	useEffect(() => {
		getAddress();
	}, [])

	const changeSelectItem = item => {
		if (seleteItem !== item) {
			setSeleteItem(item);
		}
	}
	/**
	 * 意见反馈
	 */
	const toFeedback = () => {
		history.push('/feedback');
		console.log('跳转页面');

	}
	const valid = data => {
		let message = '';
		if (!data.nickname) {
			Utils.toast.show('请填写订阅人的姓名!');
			return ;
		}
		const mobile = data.mobile.trim();
		if (!mobile) {
			Utils.toast.show('请填写订阅人的手机号!');
			return ;
		}
		if (mobile && !(/^1[1-9]\d{9}$/.test(mobile))) {
			Utils.toast.show('请填写正确的的手机号!');
			return ;
		}
		const distinctList = areaList.map(a => a.value);
		if(!distinctList.includes(cityInfo.distinct)) {
			Utils.toast.show('请选择所在区域!');
			return ;
		}

		const address = data.address.trim();
		if (!address) {
			Utils.toast.show('请填写邮寄地址!');
			return ;
		}
		return true;
	}
	/**
	 * 立即订阅点击
	 */
	const subscribeHandler = () => {
		const param = {
			nickname: userName,
			sex: gender,
			mobile,
			length: seleteItem,
			address,
			...cityInfo,
		};

		if (valid(param)) {
			console.log('立即订阅', param);
			// return
			Request.post({
				url: '/subscription/submit',
				data: param,
			}).then(res => {
				if (res.ok === 0) {
					Utils.toast.show('订阅成功');
				}
			})
		}
	}
	/**
	 * 定位
	 */
	const getAddress = async() => {
		const result = await AMap.getCurrentPosition2();
		if(result && result.info && result.info.toLocaleLowerCase() == 'success') {
			setCityInfo({
				province: result.province,
				city: result.city || result.province,
				distinct: result.district,
				township: result.township,
				zipCode: result.adcode,
			});
		}else{
			setCityInfo({
				city: '上海市',
				province: '上海市',
				distinct: '青浦区',
				zipCode: '201799',
			});
		}
		// console.log('获取定位', data, data1);
	}

	return (
		<Page {...{ page: 'home' }} {...props}>
			<section className="home-container"
			//  style={{backgroundImage: `url(${IMG_BG})`}}
			 >
				{/* <div className="head"></div> */}
				<div id="iCenter"></div>
				<div className="content">
					<div className="top-content">
						<img className="top-content-img" src={IMG_TOP} />
						<div className="top-content-wrapper">
							<div className="top-content-title">
								<div className="top-content-title-text">2025年度青浦报订阅</div>
								{/* <img className="top-content-title-img" src={IMG_LOGO} /> */}
							</div>
							<div className="top-content-sub-title">青浦区官方报&nbsp;&nbsp;免费订阅</div>
							<div className="btn" onClick={toFeedback}>意见反馈</div>
						</div>
					</div>
					{/* 订阅 */}
					<div className="second-wrapper">
						<div className="label bd-b">订阅时长</div>
						<div className="flex flex-b">
							{
								subscribeList.map(item => <div key={item}
									onClick={() => changeSelectItem(item)}
									className={`item ${seleteItem === item ? 'selected' : ''}`}
								>
									<div className="subscribe-label">定期邮寄</div>
									<div className="subscribe-year"><i className="year-num">{item}</i>年</div>
								</div>)
							}
							<div className='item-custom'>个人用户同一地址限订1份，投送地址仅限青浦区地域范围。</div>
						</div>
					</div>
					{/* 订阅人消息 */}
					<div className="subscribe-user-info">
						<div className="label bd-b">订阅人信息<span className="red-star">*</span></div>
						<div className="flex bd-b">
							<div className="label-text">联系人</div>
							<div className="flex1"><input className="input" onInput={e => setUserName(e.target.value)} type="text" placeholder="请填写订阅人姓名" /></div>
						</div>
						<div className="flex bd-b">
							<div className="label-text">手机号</div>
							<div className="flex1"><input className="input" maxLength={11} onInput={e => setMobile(e.target.value)} placeholder="请填写订阅人手机号" /></div>
						</div>
						<div className="flex bd-b">
							<div className="label-text">性别</div>
							<div className="flex1 flex flex-vc gender-wrapper">
								<div className="gender" onClick={() => setGender(1)}><span className={`cirl ${gender === 1 ? 'selected' : ''}`}></span><span>男</span></div>
								<div onClick={() => setGender(2)}><span className={`cirl ${gender === 2 ? 'selected' : ''}`}></span><span>女</span></div>
							</div>
						</div>
						<div className="flex bd-b">
							<div className="label-text">邮寄地址</div>
							<div className="flex1 flex flex-vc">
								<span>上海市青浦区</span>
								<select placeholder="所属区域" className="address-select-item" onChange={e => {
									const value = e.target.value;
									const areaItem = getAreaItem(value);
									if(areaItem) {
										cityInfo.zipCode = areaItem.zipCode;
										cityInfo.distinct = value;
									}
									setCityInfo({...cityInfo});
								}}>
									{
										[
											{ value: '-所属区域选择-', label: '-所属区域选择-', zipCode: '' },
											...areaList
										].map((item, idx) => (
											<option value={item.value}>{item.label}</option>
										))
									}
								</select>
								{/*<input className="input flex1" type="text" readOnly disabled value={`${cityInfo.province || ''}${cityInfo.city != cityInfo.province && cityInfo.city || ''}${cityInfo.distinct || ''}${cityInfo.township || ''}`} placeholder="请选择收获地址" />*/}
								{/*<div className="poi-wrapper flex0" onClick={getAddress}><img className="poi-img" src={IMG_POI} />立即定位</div>*/}
							</div>
						</div>
						<div className="flex">
							<div className="label-text"></div>
							<div className="flex1"><input className="input flex1" type="text" onInput={e => setAddress(e.target.value)} placeholder="请输入详细地址，例如1层101室" /></div>
							</div>
							</div>
						<div>
					</div>
					<div className="subscribe-btn large-btn" onClick={subscribeHandler}>立即订阅</div>
				</div>
			</section>
		</Page>
	);
};

export default PageModel;
